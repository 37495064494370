import React, { useEffect, useState } from 'react';
import { Link } from "gatsby"

const Pagination = ({ currentPage , setCurrentPage, totalPages }) => {
    
    
    let total = currentPage + 4
    
    const [pages, setPages] = React.useState([1,2,3,4])
    

    const RenderLink = (page) => {
        let link = '';
        if(window.location.hash){
            link = window.location.href.replace(window.location.hash,`#${page}`)  
        } else {
            link = `${window.location.href}#${page}`
        }
        setCurrentPage(page);
        window.scrollTo(0, 0);
    }

    React.useEffect(() => {
        let newPages = []
        if(currentPage <= totalPages && currentPage > (totalPages - 2)) {
            let calcDiff = 3 - (totalPages - currentPage)
            for(let i = currentPage - calcDiff; i < totalPages; i++) {
                newPages.push(i)
            }
        } else {
            if(currentPage <= (totalPages - 2) && currentPage > (totalPages - 4)) {
                for(let i = currentPage > 2 ? currentPage - 1 : 1; i < totalPages; i++) {
                    newPages.push(i)
                }
            } else {
                for(let i = currentPage > 2 ? currentPage - 2 : 1; i < total; i++) {
                    newPages.push(i)
                }
            }
        }

        setPages(newPages)
        
    },[currentPage, totalPages])

    if(totalPages == 0) return null


    return(
        <div className="pagination-container">
            <ul className="pagination-list">
                {
                    pages.map(page => {
                        return (
                            page > 0 &&
                                <li>
                                    <button className={currentPage == page && "active"} onClick={() => { RenderLink(page) } }>{page}</button>
                                </li>
                        )
                    })
                    
                }
                <li>
                    <button className={currentPage == totalPages && "active"} onClick={() => { RenderLink(totalPages) } }>{ totalPages }</button>
                </li>
            </ul>
        </div>
    )
}

export default Pagination;