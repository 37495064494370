import * as React from "react"

import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <section className="layout">
          { children }
      </section>
      <Footer />
    </>
  )
}

export default Layout
