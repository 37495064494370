import React from 'react';
import { Router } from "@reach/router"

// Styles
import "../styles/css/styles.css"

// Component
import Header from "../components/Header"
import Footer from "../components/Footer"
import List from "../components/List"
import Pagination from '../components/Pagination';
import Layout from "../components/Layout"
import defaults from "../components/dafaults"
import SpecifiSearch from "../components/SpecificSearch";

const Lancamentos = (props) => {
    const [ currentPage ,  setCurrentPage ] = React.useState(1);
    const [ data , setData ] = React.useState(null);
    const [ lancamentos, setLancamentos ] = React.useState(null)
    const [ typePage, setTypePage ] = React.useState(null);
    const [ specificSearchCustom, setSpecificSearchCustom ] = React.useState(null);
    const [ loadingMessage, setLoadingMessage ] = React.useState('');

    const [ notFound, setNotFound ] = React.useState(null);

    const [ find, setFind ] = React.useState(null);

    const [ total , setTotal ] = React.useState(0);
    const [ totalAdv , setTotalAdv ] = React.useState(0);

    const [ message , setMessage ] = React.useState(null);

    const render = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        if(window.location.pathname == '/lancamentos' || window.location.pathname == '/lancamentos/'){

            if(find && find.length >= 2){

                setLoadingMessage('Carregando...');
                
                fetch(`${defaults.api.rest}lancamento?per_page=24&page=${currentPage}&_embed&search=${find}`)
                    .then(resp => {
                        setTotalAdv(resp.headers.get('x-wp-total'));
                        return resp.json()
                    })
                    .then(data => {

                        if(data.length > 0) {
                            if(specificSearchCustom == null || specificSearchCustom[currentPage] == undefined) {
                                setSpecificSearchCustom({...specificSearchCustom, [currentPage]: data })
                            } 
                        } else {
                            setNotFound("Nenhum resultado encontrado!")
                        }
                            
                    }).catch(err => console.log(err))
    
            } else {

                setSpecificSearchCustom(null);

                fetch(`${defaults.api.rest}lancamento?per_page=24&page=${currentPage}&_embed`, requestOptions)
                .then(response =>{
                    setTotal(response.headers.get('x-wp-total'));
                    return response.json()
                })
                .then(result => {
                    if(lancamentos == null || lancamentos[currentPage] == undefined){
                        setLancamentos({...lancamentos, [currentPage]: result })
                        setTypePage('list');
                    }

                    setLoadingMessage('');
                })
                .catch(error => console.log('error', error));

            }


        }else {
            let slug = window.location.pathname.split('/')[2];
            
            fetch(`${defaults.api.rest}lancamento?slug=${slug}&per_page=24&page=${currentPage}&_embed`, requestOptions)
            .then(response => {
                setTotal(response.headers.get('x-wp-total'));
                return response.json()
            })
            .then(result => {
                if(slug !== currentPage) {
                    setCurrentPage(slug)
                    setData(result[0])
                    setTypePage('detail');
                }
                setLoadingMessage('');
            })
            .catch(error => console.log('error', error));
        }
    }

    const searchCategory = (event) => {
        const search = event.target.value;
        setSpecificSearchCustom(null)
        setFind(search)
        setLoadingMessage('');
    }

    React.useLayoutEffect(() => {
        render();
    })

    React.useEffect(() => {
        render();
    }, [currentPage])

    const handleKeyDown = (event) => {
        setLancamentos(null);
        if (event.key === 'Enter') {
            const search = event.target.value;
            setSpecificSearchCustom(null)
            setFind(search)
            setLoadingMessage('');
        }
    }

    return(
        <>
            <Header/> 
                {
                    typePage == 'list' ?
                        <section className="dicasPage">
                            <div className="container dicas-title">
                                <h2>Lançamentos</h2>
                                {/* <div className="navigation">
                                    <a href="/dicas-tecnicas">Ver todas as dicas</a>
                                </div> */}
                            </div>
                            <div className="lancamento_search_container">
                                <input className='search__home-custom' type="text" onKeyDown={handleKeyDown} placeholder="Buscar..."/>
                                <small>Utilize o "enter" para processar sua busca</small>
                                {/* <SpecifiSearch 
                                    setSpecificSearchCustom={setSpecificSearchCustom}
                                    endpoint={`https://admin.wegamotors.com/wp-json/wp/v2/lancamento&per_page=24&page=${currentPage}?_embed&search=`}
                                    setLoadingMessage={setLoadingMessage}
                                /> */}
                                
                            </div>
                            <div className="dicas-list">
                                {
                                    <>
                                        {
                                            specificSearchCustom != null ?
                                                <>
                                                    <List datas={specificSearchCustom[currentPage]} message={message} buttonLabel="Saiba Mais" path="/lancamentos" />
                                                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={parseInt(totalAdv / 24)} />
                                                </>
                                            :
                                                lancamentos != null ?
                                                    <>
                                                        <List datas={lancamentos[currentPage]} buttonLabel="Saiba Mais" path="/lancamentos" />
                                                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={parseInt(total / 24)} />
                                                    </>
                                                : <p className='notFound'>Nenhum resultado encontrado</p>
                                        }
                                    </>
                                }
                            </div>
                        </section>
                    :    
                        <div className="content somesubpage" id={props.id}>
                            <div className="flex">
                                <h1 className="title" dangerouslySetInnerHTML={{__html: data?.title.rendered }}></h1>
                            </div>
                            <div className="insideContent">
                                <img src={data?._embedded["wp:featuredmedia"][0].source_url} alt={data?.title.rendered} />
                                <div dangerouslySetInnerHTML={{__html: data?.content.rendered }} />
                            </div>
                        </div>
                }
                
            {/* <List datas={data} buttonLabel="Saiba Mais" path="/teste" /> */}
            <Footer/>
        </>
    )
}

// const Lancamentos = ({ serverData }) => (
//     <Layout>
//       <Router>
//         <SomeSubPage path="/lancamentos/:id" />
//       </Router>
//     </Layout>
//   )

export default Lancamentos;