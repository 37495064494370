import * as React from "react"
import '../../styles/css/styles.css';

const Loading = () => {
    return(
        <section className="main-loading">
            <img src="/icons/spinner.svg" alt="loading" />
            <h2 className="loading">Carregando...</h2>
        </section>
    )
}

export default Loading